import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { EngagementListItem } from "../index";

import { getEngagements } from "../../services/engagementServices";
import { Treadmill } from "../loading/treadmill";

const EngagementList = ({ search }) => {
  const [engagements, setEngagements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredEngagements, setFilteredEngagements] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getEngagements();
      setEngagements(result);
      setFilteredEngagements(result);
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (search != "") {
      setFilteredEngagements(
        engagements.filter((engagement) =>
          engagement.client.nom.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    } else {
      setFilteredEngagements(engagements);
    }
  }, [search]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Treadmill />
      </div>
    );
  }

  return (
    // LISTE D'ENGAGEMENTS
    <ul role="list" className="divide-y divide-white/5">
      {filteredEngagements.map((engagement, key) => (
        // ENGAGEMENT
        <EngagementListItem key={key} engagement={engagement} />
      ))}
    </ul>
  );
};

// props
EngagementList.propTypes = {
  search: PropTypes.string.isRequired,
};

export default EngagementList;
