import React, { useState, useEffect, useCallback } from "react";
import SideBar from "../navbar/sidebar";
import EngagementHeader from "./engagementheader";
import EngagementBody from "./engagementbody";
import {
  getEngagementById,
  getApplicationsIds,
} from "../../services/engagementServices";
import { useParams } from "react-router-dom";
import { Treadmill } from "../loading/treadmill";

export default function Engagement() {
  const queryParameters = new URLSearchParams(window.location.search);
  const [engagement, setEngagement] = useState(null);
  const [applicationsIds, setApplicationsIds] = useState([]);
  const [screensMap, setScreensMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [screenId, setScreenId] = useState(queryParameters.get("screen") || 2);
  const { engagementId } = useParams();

  // Fonction pour changer l'écran
  const handleNavigationChange = useCallback((id) => {
    setScreenId(id);
    // Set url params to the current screen id
    window.history.pushState(
      {},
      "",
      `/engagement/${engagementId}?screen=${id}`,
    );
  }, []);

  // Fonction pour récupérer les données de l'engagement
  async function fetchData() {
    try {
      const result = await getEngagementById(engagementId);
      setEngagement(result);
      transformScreensToMap(result.screens);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }

  // Fonction pour récupérer les ids des applications
  async function fetchApplicationsIds() {
    try {
      const result = await getApplicationsIds(engagementId);
      setApplicationsIds(result);
    } catch (e) {
      setError(e.message);
    }
  }

  // Récupérer les données de l'engagement au chargement du composant
  useEffect(() => {
    fetchData();
    fetchApplicationsIds();
  }, []);

  // Fonction pour mettre à jour les données
  const handleDataUpdate = async () => {
    await fetchData();
  };

  // Récupérer les ids des applications
  const handleApplcationUpdate = async () => {
    await fetchApplicationsIds();
    handleDataUpdate();
  };

  // Fonction pour transformer les données des écrans en map
  const transformScreensToMap = (screens) => {
    let map = {};
    const flattenScreens = (screens) => {
      screens.forEach((screen) => {
        map[screen.id] = screen;
        if (screen.children && screen.children.length > 0) {
          flattenScreens(screen.children);
        }
      });
    };
    flattenScreens(screens);
    setScreensMap(map);
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <Treadmill />
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="h-[calc(100vh-65px)] flex bg-gray-800">
      <SideBar
        navigation={engagement.screens}
        onNavigate={handleNavigationChange}
      />
      <main className="w-full overflow-y-scroll">
        <EngagementHeader data={engagement} />
        <div className="mt-4 mx-4 pt-8 pb-12 rounded bg-gray-900 flex flex-col items-center justify-start border border-gray-700">
          <EngagementBody
            engagementData={engagement}
            screenData={screensMap[screenId]}
            applicationsIds={applicationsIds}
            onApplicationUpdated={handleApplcationUpdate}
            updateData={handleDataUpdate}
            onNavigate={handleNavigationChange}
          />
        </div>
      </main>
    </div>
  );
}
