import React, { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import { Listbox, Transition } from "@headlessui/react";
import { useAuth } from "../contexts/AuthContext";

import { modifyUserById } from "../services/userServices";

import SuccessAlert from "./alerts/successalert";

// import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { addTeam } from "../services/teamServices";
import { getAllUsers } from "../services/userServices";

export default function profilemodifyform() {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    firstname: "",
    fonction: "",
    description: "",
  });
  const [isCreated, setIsCreated] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const updatedData = {
      name: event.target.name.value || currentUser.name,
      firstname: event.target.firstname.value || currentUser.firstname,
      fonction: event.target.fonction.value || currentUser.fonction,
      description: event.target.description.value || currentUser.description,
    };

    try {
      await modifyUserById(currentUser.uid, updatedData);
      setIsCreated(true);
    } catch (error) {
      console.error("Error updating profile: ", error);
      alert("Failed to update profile.");
    }
  };

  // Fonction pour fermer l'alerte de succès
  const handleDismiss = () => {
    setIsCreated(false);
  };

  return (
    <div className="isolate bg-gray-900 px-6 py-10 sm:py-10 lg:px-8">
      {isCreated && (
        <SuccessAlert
          onDismiss={handleDismiss}
          text="Profile modifié avec succès !"
        />
      )}
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Modifier votre profil
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-400">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores
        </p>
        <div className="w-full flex justify-center">
          <img
            src={currentUser.profilePictureUrl}
            className="shadow-xl rounded-full border-none mt-10 max-w-[150px]"
          />
        </div>
      </div>
      <form className="mx-auto mt-10 max-w-xl sm:mt-10" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <label
              htmlFor="equipe"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Nom
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="name"
                id="name"
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={currentUser.name}
              />
            </div>
          </div>
          <div className="sm:col-span-1">
            <label
              htmlFor="equipe"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Prénom
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="firstname"
                id="firstname"
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={currentUser.firstname}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="equipe"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Rôle
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="fonction"
                id="fonction"
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={currentUser.fonction}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="equipe"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Description
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="description"
                id="description"
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={currentUser.description}
              />
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Modifier
          </button>
        </div>
      </form>
    </div>
  );
}
