import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Input from "../inputs/input";
import { db } from "../../config/firebase";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import {
  getInputsByIds,
  updateInputValueById,
  deleteFileFromInput,
  downloadFileFromStorage,
} from "../../services/engagementServices";
import SuccessAlert from "../alerts/successalert";
import { Treadmill } from "../loading/treadmill";

import DocumentEditModal from "../modals/documenteditmodal";
import DocumentAddModal from "../modals/documentaddmodal";

import { useAuth } from "../../contexts/AuthContext";
import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";
import DocumentViewerModal from "../modals/documentviewermodal";
import { set } from "date-fns";

export default function EngagementBodyInputs({ data, updateData }) {
  const [inputs, setInputs] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editingUsers, setEditingUsers] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const { currentUser } = useAuth();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [activeInput, setActiveInput] = useState({});
  const [activeAddFile, setActiveAddFile] = useState({
    index: null,
    file: {},
    input_uid: "",
  });
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  // Fonction pour récupérer les inputs par leurs ids
  async function getInputs() {
    try {
      const result = await getInputsByIds(data.inputs);
      setInputs(result);
      const initialValues = result.reduce(
        (acc, input) => ({
          ...acc,
          [input.uid]: input.value || "",
        }),
        {},
      );
      setFormValues(initialValues);
    } catch (e) {
      console.error(e);
    }
  }

  // Récupérer les inputs au chargement du composant
  useEffect(() => {
    setLoading(true);
    if (data.inputs) {
      const fetchData = async () => {
        await getInputs();
        setLoading(false);
      };
      fetchData();
    } else {
      setLoading(false);
      setInputs([]);
    }
  }, [data.inputs]);

  // Récupérer la référence screen firebase pour écouter en direct les changements
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    const docRef = doc(db, "screens", data.uid);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setEditingUsers(data.editingUsers || []);
      }
    });

    return async () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      unsubscribe();
      setEditingUsers([]);
    };
  }, []);

  const handleBeforeUnload = async () => {
    const newEditingUsers = editingUsers.filter(
      (user) => user !== currentUser.uid,
    );
    console.log("newEditingUsers", newEditingUsers);
    const docRef = doc(db, "screens", data.uid);
    updateDoc(docRef, {
      editingUsers: newEditingUsers,
    })
      .then(() => {
        console.log("Document updated successfully");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  const handleChange = (uid, value, type) => {
    setFormValues((prev) => {
      const newValue =
        type === "checkbox"
          ? prev[uid]?.includes(value)
            ? prev[uid].filter((item) => item !== value)
            : [...(prev[uid] || []), value]
          : value;
      return { ...prev, [uid]: newValue };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      inputs.forEach((input) => {
        if (formValues[input.uid]) {
          updateInputValueById(input.uid, formValues[input.uid]);
        }
      });

      setIsUpdated(true);
      getInputs();
    } catch (e) {
      console.error(e);
    }
  };

  const handleDismiss = () => {
    setIsUpdated(false);
  };

  // Handle delete file
  const handleDeleteFile = async (input_uid, index) => {
    try {
      await deleteFileFromInput(input_uid, index);
    } catch (error) {
      console.error("Error deleting file: ", error);
    }
  };

  const handleDocumentViwer = async (url) => {
    const downloadedFileUrl = await downloadFileFromStorage(url);
    setFileUrl(downloadedFileUrl);
    setOpenDocumentModal(true);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Treadmill />
      </div>
    );
  }

  if (inputs.length === 0) {
    return (
      <div className="flex justify-center items-center h-92 mt-10">
        <h1 className="text-3xl font-bold text-gray-400">No data found</h1>
      </div>
    );
  }

  return (
    <>
      {isUpdated && (
        <div className="fixed top-10 right-20">
          <SuccessAlert
            onDismiss={handleDismiss}
            text="Vos modification ont été enregistrées avec succès."
          />
        </div>
      )}

      {/* <Docviewercomponent></Docviewercomponent> */}

      <DocumentAddModal
        open={openAddModal}
        setOpen={setOpenAddModal}
        data={activeInput}
        updateData={updateData}
      />

      <DocumentEditModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        data={activeAddFile}
        input_uid={activeInput.uid}
        updateData={updateData}
      />

      <DocumentViewerModal
        open={openDocumentModal}
        setOpen={setOpenDocumentModal}
        url={fileUrl}
      ></DocumentViewerModal>

      <form
        className="w-full mt-10 flex flex-col justify-center items-center"
        onSubmit={handleSubmit}
      >
        <div className="w-4/5">
          {inputs.map((input, index) => (
            <div key={index}>
              <div
                id="row_id"
                key={index}
                className="flex justify-between items-center m-8"
                onMouseEnter={() => setHoveredRow(index)}
                onMouseLeave={() => setHoveredRow(null)}
              >
                {/* Input zone */}
                <div className="w-full">
                  {/* Input */}
                  <Input
                    data={{
                      ...input,
                      value: formValues[input.uid],
                    }}
                    type={input.type}
                    onChange={(value) =>
                      handleChange(input.uid, value, input.type)
                    }
                  />

                  {/* Document zone */}
                  {/* If inputs.files_list is exists and is not empty, map all document in the list */}
                  {input.files_list &&
                    input.files_list.map((file, index) => (
                      <div
                        key={index}
                        className="w-full flex items-center justify-between"
                      >
                        <h1
                          className="text-gray-400 text-sm mt-2 italic"
                          style={{ cursor: "pointer" }}
                          onClick={function () {
                            setActiveInput(input);
                            setActiveAddFile({ index, file });
                            setOpenEditModal(true);
                          }}
                        >
                          {file.name}
                        </h1>
                        <div className="flex items-center justify-center gap-2">
                          <EyeIcon
                            className="h-6 w-6 text-white hover:text-gray-400"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={function () {
                              file.url
                                ? handleDocumentViwer(file.url)
                                : handleDocumentViwer(null);
                            }}
                          />
                          <PencilIcon
                            className="h-6 w-6 text-white hover:text-gray-400"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={function () {
                              setActiveInput(input);
                              setActiveAddFile({ index, file });
                              setOpenEditModal(true);
                            }}
                          />
                          <TrashIcon
                            className="h-6 w-6 text-white hover:text-gray-400"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={function () {
                              handleDeleteFile(input.uid, index);
                              updateData();
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>

                {/* Box add document */}
                <div
                  id="box_add_id"
                  className={`transition-opacity duration-300 ${hoveredRow === index ? "opacity-100 border-2 border-gray-700 hover:bg-gray-700 hover:border-gray-900" : "opacity-0"}`}
                  style={{
                    width: "6rem",
                    height: "6rem",
                    marginLeft: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.5rem",
                    cursor: "pointer",
                  }}
                  onClick={function () {
                    setActiveInput(input);
                    setOpenAddModal(true);
                  }}
                >
                  {hoveredRow === index && (
                    <h1 className="text-white text-4xl font-bold">+</h1>
                  )}
                </div>
              </div>
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-500" />
                </div>
                <div className="relative flex justify-center"></div>
              </div>
            </div>
          ))}
        </div>

        <div className="fixed bottom-10 right-20">
          <button
            type="submit"
            className="block w-32 rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sauvegarder
          </button>
        </div>
      </form>
    </>
  );
}

EngagementBodyInputs.propTypes = {
  data: PropTypes.object,
  updateData: PropTypes.func,
};
