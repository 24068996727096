import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getClientById, updateClientById } from "../../services/clientServices";
import { Treadmill } from "../loading/treadmill";

export default function ClientEditSection() {
  const [client, setClient] = useState(null); // Initialiser à null pour représenter "pas de données"
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    nom: "",
    email: "",
    adresse_postale: "",
    num_tel: "",
    secteur_activite: "",
    description: "",
  });

  const { clientId } = useParams(); // Utilise useParams pour récupérer l'id du client depuis l'URL
  const navigate = useNavigate(); // Pour rediriger après la soumission

  useEffect(() => {
    const fetchData = async () => {
      const result = await getClientById(clientId);
      setClient(result);
      setFormData({
        nom: result.nom || "",
        email: result.email || "",
        adresse_postale: result.adresse_postale || "",
        num_tel: result.num_tel || "",
        secteur_activite: result.secteur_activite || "",
        description: result.description || "",
      });
      setLoading(false);
    };
    fetchData();
  }, [clientId]); // Ajouter clientId aux dépendances pour recharger si l'ID change

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data", formData);
    await updateClientById(clientId, formData); // Appelle à la fonction de mise à jour
    navigate(`/client/${clientId}`); // Redirection après la soumission
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Treadmill />
      </div>
    );
  }

  if (!client) {
    return <div>No client data found.</div>; // Afficher un message si aucun client n'est trouvé
  }

  return (
    <div id="div1" className="flex flex-col items-center justify-center mt-10">
      <div id="div2" className="w-full max-w-2xl">
        <div className="px-4 sm:px-0">
          <h3 className="text-lg font-semibold leading-7 text-white">
            Modifier les informations du client
          </h3>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Modifiez les détails et informations du client ci-dessous.
          </p>
        </div>
      </div>
      <div id="div3" className="w-full max-w-2xl">
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200 mt-10"
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            {/* Répéter cette structure pour chaque champ d'information du client */}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="nom"
                className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
              >
                Nom
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="nom"
                    id="nom"
                    value={formData.nom}
                    onChange={handleChange}
                    className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                  />
                </div>
              </div>
            </div>

            {/* Adresse email */}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
              >
                Adresse email
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                  />
                </div>
              </div>
            </div>

            {/* Adresse postale */}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="adresse_postale"
                className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
              >
                Adresse postale
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="adresse_postale"
                    id="adresse_postale"
                    value={formData.adresse_postale}
                    onChange={handleChange}
                    className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                  />
                </div>
              </div>
            </div>

            {/* Téléphone */}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="num_tel"
                className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
              >
                Téléphone
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="num_tel"
                    id="num_tel"
                    value={formData.num_tel}
                    onChange={handleChange}
                    className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                  />
                </div>
              </div>
            </div>

            {/* Secteur */}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="secteur_activite"
                className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
              >
                Secteur
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="secteur_activite"
                    id="secteur_activite"
                    value={formData.secteur_activite}
                    onChange={handleChange}
                    className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                  />
                </div>
              </div>
            </div>

            {/* Description */}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
              >
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                  id="description"
                  name="description"
                  rows={3}
                  value={formData.description}
                  onChange={handleChange}
                  className="max-w-lg shadow-sm bg-gray-800 text-white block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-700 rounded-md"
                />
                <p className="mt-2 text-sm text-gray-400">
                  Décrire le client en quelques mots.
                </p>
              </div>
            </div>
          </div>
          <div className="px-4 py-6 sm:px-0 flex justify-end">
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Enregistrer les modifications
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
