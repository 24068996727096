import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

export default function Docviewercomponent({ url }) {
  const url_cors = "https://corsproxy.io/?" + encodeURIComponent(url);
  const [fileType, setFileType] = useState(null);
  // Fetch json from url

  useEffect(() => {
    fetch(url.split("?")[0])
      .then((response) => response.json())
      .then((data) => setFileType(data.contentType))
      .catch((error) => console.error("Error fetching document: ", error));
  });

  console.log("fileType: ", fileType);

  const docs = [
    {
      uri: url_cors,
      fileType: fileType,
    },
  ];

  if (url == null) {
    return (
      <div>
        <h1>Document inexistant ou incompatible</h1>
      </div>
    );
  } else {
    try {
      return (
        <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
      );
    } catch (error) {
      console.error("Error rendering document: ", error);
      return (
        <div>
          <h1>Erreur lors du rendu du document</h1>
        </div>
      );
    }
  }
}

// Prop validation
Docviewercomponent.propTypes = {
  url: PropTypes.string.isRequired,
};
