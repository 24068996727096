import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getClientById } from "../../services/clientServices";
import { Treadmill } from "../loading/treadmill";

export default function ClientSection() {
  const [client, setClient] = useState(null); // Initialiser à null pour représenter "pas de données"
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { clientId } = useParams(); // Utilise useParams pour récupérer l'id du client depuis l'URL

  useEffect(() => {
    const fetchData = async () => {
      const result = await getClientById(clientId);
      setClient(result);
      setLoading(false);
    };
    fetchData();
  }, [clientId]); // Ajouter clientId aux dépendances pour recharger si l'ID change

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Treadmill />
      </div>
    );
  }

  if (!client) {
    return <div>No client data found.</div>; // Afficher un message si aucun client n'est trouvé
  }

  return (
    <div id="div1" className="flex flex-col items-center justify-center mt-10">
      <div
        id="div2"
        className="w-full max-w-2xl flex justify-between items-center"
      >
        <div className="px-4 sm:px-0">
          <h3 className="text-lg font-semibold leading-7 text-white">
            Information du client
          </h3>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Details et informations du client.
          </p>
        </div>
        <div>
          <button
            className="bg-black/10 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
            onClick={() => navigate(`/clientedit/${clientId}`)}
          >
            Modifier
          </button>
        </div>
      </div>
      <div id="div3" className="w-full max-w-2xl">
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {/* Répéter cette structure pour chaque information du client */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Nom</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                {client.nom}
              </dd>
            </div>
            {/* Ajouter d'autres champs en répétant la structure ci-dessus */}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">
                Adresse email
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                {client.email}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">
                Adresse postale
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                {client.adresse_postale}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">
                Téléphone
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                {client.num_tel}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">
                Secteur
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                {client.secteur_activite}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">
                Description
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                {client.description}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
