import React, { useEffect, useState } from "react";
import { BriefcaseIcon, StarIcon } from "@heroicons/react/outline";
import SectionHeader from "../sectionheader";
import { getAllClients } from "../../services/clientServices";
import { Treadmill } from "../loading/treadmill";
import { updateFavorite } from "../../services/userServices"; // Assurez-vous que cette importation est correcte
import { useAuth } from "../../contexts/AuthContext";

export default function ClientsSection() {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hoveredClientId, setHoveredClientId] = useState(null);
  const [favoriteClients, setFavoriteClients] = useState([]); // Liste des favoris
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      // Remplacez la logique ci-dessous pour récupérer les clients et les favoris de l'utilisateur
      const clientsResult = await getAllClients();
      setClients(clientsResult);

      // Simulez une récupération des favoris de l'utilisateur (à remplacer par une vraie méthode de récupération)
      currentUser.favoriteClients
        ? setFavoriteClients(currentUser.favoriteClients)
        : setFavoriteClients([]);

      setLoading(false);
    };
    fetchData();
  }, [currentUser]);

  const handleFavoriteToggle = async (clientId) => {
    try {
      await updateFavorite(currentUser.uid, clientId);
      // Met à jour la liste des favoris localement
      setFavoriteClients((prevFavorites) =>
        prevFavorites.includes(clientId)
          ? prevFavorites.filter((id) => id !== clientId)
          : [...prevFavorites, clientId],
      );
    } catch (error) {
      console.error("Failed to update favorite client: ", error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Treadmill />
      </div>
    );
  }

  return (
    <section className="text-gray-400 body-font bg-gray-900">
      <div className="container px-5 mx-auto">
        <SectionHeader
          label={"Clients"}
          link={""}
          Icon={BriefcaseIcon}
          Create="client/create"
          Link=""
        />
        <div className="flex flex-wrap m-4">
          {clients.map((client) => {
            const isFavorite = favoriteClients.includes(client.id);
            const isHovered = hoveredClientId === client.id;
            const starColor =
              isFavorite || isHovered ? "text-yellow-300" : "text-gray-500";

            return (
              <a
                key={client.id}
                className="xl:w-1/3 md:w-1/2 p-4"
                href={"/client/" + client.id}
                onMouseEnter={() => setHoveredClientId(client.id)}
                onMouseLeave={() => setHoveredClientId(null)}
              >
                <div className="border border-gray-700 border-opacity-75 p-6 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out relative">
                  <div className="flex justify-between">
                    <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-400 mb-4">
                      <BriefcaseIcon className="w-6 h-6" />
                    </div>
                    {hoveredClientId === client.id &&
                      !favoriteClients.includes(client.id) && ( // Afficher l'étoile uniquement si le client est survolé
                        <StarIcon
                          className="w-6 h-6 text-gray-300 cursor-pointer hover:text-yellow-300"
                          onClick={(e) => {
                            e.preventDefault(); // Empêcher la navigation lors du clic sur l'étoile
                            handleFavoriteToggle(client.id);
                          }}
                        />
                      )}
                    {favoriteClients.includes(client.id) && ( // Afficher l'étoile uniquement si le client est survolé
                      <StarIcon
                        className="w-6 h-6 text-yellow-400 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault(); // Empêcher la navigation lors du clic sur l'étoile
                          handleFavoriteToggle(client.id);
                        }}
                      />
                    )}
                  </div>
                  <h2 className="text-lg text-white font-medium title-font mb-2">
                    {client.nom}
                  </h2>
                  <p className="leading-relaxed text-base line-clamp-2 overflow-hidden overflow-ellipsis">
                    {client.description}
                  </p>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
}
