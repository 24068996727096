import { React, useState, Fragment, useEffect } from "react";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import SuccessAlert from "../alerts/successalert";
import { BriefcaseIcon, ArchiveIcon } from "@heroicons/react/outline";

import { useAuth } from "../../contexts/AuthContext";

import {
  createTestClients,
  getAllClients,
} from "../../services/clientServices";

import { createEngagement } from "../../services/engagementServices";

import { Treadmill } from "../loading/treadmill";

export default function engagementcreateform() {
  const types = [
    {
      id: 1,
      nom: "Audit IT - CAC",
      avatar: "",
    },
  ];
  const [client_selected, setClientSelected] = useState({
    nom: "Selectionner un client",
  });
  const [type_selected, setTypeSelected] = useState({
    nom: "Selectionner un type d'engagement",
  });
  const [clients, setClients] = useState([]);
  const [isCreated, setIsCreated] = useState(false);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAllClients();
      setClients(result);
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    // Empêcher le rechargement de la page
    e.preventDefault();
    try {
      await createEngagement({
        client: client_selected,
        type: type_selected,
        creator_id: currentUser.uid,
        description: e.target.description.value,
      });

      setIsCreated(true);
    } catch (error) {
      console.error("Erreur lors de la création de l'engagement:", error);
    }
  };

  // Fonction pour fermer l'alerte de succès
  const handleDismiss = () => {
    setIsCreated(false);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Treadmill />
      </div>
    );
  }

  return (
    <div className="isolate bg-gray-900 px-6 py-24 sm:py-10 lg:px-8">
      {isCreated && (
        <SuccessAlert
          onDismiss={handleDismiss}
          text="Engagement créé avec succès !"
        />
      )}
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Création d&apos;un engagement
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-400">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores
        </p>
      </div>
      <div className="flex justify-center mt-10">
        <form
          className="space-y-8 divide-y divide-gray-200 w-3/5 mt-10"
          onSubmit={handleSubmit}
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="mt-6 sm:border-t sm:border-gray-200 pt-4 grid grid-cols-2 gap-4">
              {/* Client */}
              <div className="">
                <Listbox value={client_selected} onChange={setClientSelected}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="mb-2 block text-sm font-medium text-white">
                        Client
                      </Listbox.Label>
                      <div className="relative">
                        <Listbox.Button className="relative w-full border-gray-700 bg-gray-800 text-white rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                          <span className="flex items-center">
                            {/* <img
                            src={selected.avatar}
                            alt=""
                            className="flex-shrink-0 h-6 w-6 rounded-full"
                          /> */}
                            <BriefcaseIcon className="w-6 h-6" />
                            <span className="ml-3 block truncate">
                              {client_selected.nom}
                            </span>
                          </span>
                          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          className="absolute mt-1 w-full rounded-md bg-gray-800 shadow-lg"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 w-full bg-gray-800 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {clients.map((client) => (
                              <Listbox.Option
                                key={client.uid}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-blue-600 flex items-center"
                                      : "text-white flex items-center",
                                    "cursor-default select-none relative py-2 pl-3 pr-9",
                                  )
                                }
                                value={client}
                              >
                                {({ client_selected, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      {/* <img
                                      src={client.avatar}
                                      alt=""
                                      className="flex-shrink-0 h-6 w-6 rounded-full"
                                    /> */}
                                      <BriefcaseIcon className="w-6 h-6" />
                                      <span
                                        className={classNames(
                                          client_selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate",
                                        )}
                                      >
                                        {client.nom}
                                      </span>
                                    </div>

                                    {client_selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4",
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>

              {/* Type */}
              <div className="">
                <Listbox value={type_selected} onChange={setTypeSelected}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="mb-2 block text-sm font-medium text-white">
                        Type
                      </Listbox.Label>
                      <div className="relative">
                        <Listbox.Button className="relative w-full border-gray-700 bg-gray-800 text-white rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                          <span className="flex items-center">
                            {/* <img
                            src={selected.avatar}
                            alt=""
                            className="flex-shrink-0 h-6 w-6 rounded-full"
                          /> */}
                            <ArchiveIcon className="w-6 h-6" />
                            <span className="ml-3 block truncate">
                              {type_selected.nom}
                            </span>
                          </span>
                          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          className="absolute mt-1 w-full rounded-md bg-gray-800 shadow-lg"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 w-full bg-gray-800 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {types.map((type) => (
                              <Listbox.Option
                                key={type.uid}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-blue-600 flex items-center"
                                      : "text-white flex items-center",
                                    "cursor-default select-none relative py-2 pl-3 pr-9",
                                  )
                                }
                                value={type}
                              >
                                {({ type_selected, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      {/* <img
                                      src={type.avatar}
                                      alt=""
                                      className="flex-shrink-0 h-6 w-6 rounded-full"
                                    /> */}
                                      <ArchiveIcon className="w-6 h-6" />
                                      <span
                                        className={classNames(
                                          type_selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate",
                                        )}
                                      >
                                        {type.nom}
                                      </span>
                                    </div>

                                    {type_selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4",
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
            </div>

            {/* Description */}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="about"
                className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
              >
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                  id="description"
                  name="description"
                  rows={3}
                  className="max-w-lg shadow-sm bg-gray-800 text-white block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-700 rounded-md"
                  defaultValue={""}
                />
                <p className="mt-2 text-sm text-gray-400">
                  Description de l&apos;engagement.
                </p>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Créer
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
