import React from "react";
import PropTypes from "prop-types";
/* This example requires Tailwind CSS v2.0+ */

import SideBarItem from "./sidebaritem";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideBar({ navigation, onNavigate }) {
  const queryParameters = new URLSearchParams(window.location.search);
  const currentScreenId = queryParameters.get("screen");

  // Set the current item to active when the screen id matches the item id
  function markCurrentItems(navigation, currentScreenId) {
    navigation.forEach((item) => {
      if (item.id === currentScreenId) {
        item.current = true;
      } else {
        item.current = false;
      }
      if (item.children) {
        markCurrentItems(item.children, currentScreenId); // Appel récursif pour chaque élément enfant
      }
    });
  }

  markCurrentItems(navigation, currentScreenId);

  return (
    <div className="flex w-1/5 flex-col flex-grow border-r border-gray-700 pb-4 bg-gray-900 overflow-y-auto">
      <div className="mt-5 flex-grow flex flex-col">
        <nav className="flex-1 px-2 space-y-1" aria-label="Sidebar">
          {navigation.map((item) => (
            <SideBarItem key={item.id} item={item} onNavigate={onNavigate} />
          ))}
        </nav>
      </div>
    </div>
  );
}

SideBar.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          href: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  onNavigate: PropTypes.func.isRequired,
};
